import React from "react";
import Footer from "../../components/footer";
import NavBar from "../../components/nav/index";
import Blurb from "../../components/blurb";
import "./index.scss";

interface CareersProps {}

export const Careers: React.FC<CareersProps> = () => {

  return (
    <div className="contact-page">
      <NavBar internal />
      <div className="content-wrapper">

      <Blurb
          heading="Join our Team"
          subtext="At our company, we offer high-quality service and innovative solutions, supported by a collaborative and supportive team environment. We provide opportunities for professional growth and development, allowing you to engage in impactful projects within the Oil and Gas industry. Our transparent and value-driven processes ensure that you are part of a trustworthy organization. Additionally, we offer contract remote positions and flexible work hours to accommodate your work-life balance needs."
        />
        <iframe className="careers-iframe" height="700px" src="https://app.dover.com/jobs/energypointllc?embed=1" frameBorder="0" />
      </div>
      <Footer />
    </div>
  );
};
export default Careers;
