import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../components/footer";
import NavBar from "../../components/nav/index";
import "./index.scss";

interface Post {
  id: number;
  title: { rendered: string };
  content: { rendered: string };
  featured_media: number;
  author: number;
}

interface Author {
  id: number;
  name: string;
}

const PostPage: React.FC = () => {
  const { postId } = useParams<{ postId: string }>();
  const [post, setPost] = useState<Post | null>(null);
  const [author, setAuthor] = useState<Author | null>(null);
  const [featuredImageUrl, setFeaturedImageUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        // Fetch post details
        const postResponse = await fetch(
          `https://energypointllc.com/news/wp-json/wp/v2/posts/${postId}`
        );
        if (!postResponse.ok) {
          throw new Error("Failed to fetch post");
        }
        const postData: Post = await postResponse.json();
        setPost(postData);

        // Fetch author details
        const authorResponse = await fetch(
          `https://energypointllc.com/news/wp-json/wp/v2/users/${postData.author}`
        );
        if (!authorResponse.ok) {
          throw new Error("Failed to fetch author");
        }
        const authorData: Author = await authorResponse.json();
        setAuthor(authorData);

        // Fetch featured image URL
        if (postData.featured_media) {
          const imageUrlResponse = await fetch(
            `https://energypointllc.com/news/wp-json/wp/v2/media/${postData.featured_media}`
          );
          if (!imageUrlResponse.ok) {
            throw new Error("Failed to fetch featured image URL");
          }
          const imageUrlData: { source_url: string } =
            await imageUrlResponse.json();
          setFeaturedImageUrl(imageUrlData.source_url);
        }
      } catch (error: any) {
        console.error("Error fetching post details:", error.message);
      }
    };

    fetchPost();
  }, [postId]);

  if (!post || !author) {
    return <div>Loading...</div>;
  }

  return (
    <div className="post-page">
      <NavBar internal />
      <div className="content-wrapper">
        <h1>{post.title.rendered}</h1>
        <p className="author">Written By {author.name}</p>
        {featuredImageUrl && (
          <img
            src={featuredImageUrl}
            alt="Featured"
            style={{ width: "100%", height: "auto" }}
            className="featured-image"
          />
        )}
        <div
          className="post-content"
          dangerouslySetInnerHTML={{ __html: post.content.rendered }}
        />
      </div>
      <Footer />
    </div>
  );
};

export default PostPage;
