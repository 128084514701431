import React from "react";
import "./index.scss";

const stats = [
  { number: "$4.7B", stat: "in asset data migration" },
  { number: "$6.7M", stat: "resolved unclaimed property" },
  { number: 114, stat: "satisfied clients" },
  { number: "14.5", stat: "average years of experience per analyst" },
  { number: "25+", stat: "distinct software platforms" },
];
function Stats() {
  return (
    <div className="stats">
      {stats.map((stat) => {
        return (
          <div className="stat">
            <div>{stat.number}</div>
            <div>{stat.stat}</div>
          </div>
        );
      })}
    </div>
  );
}
export default Stats;
