import React, { useState } from "react";
import NavBar from "../../components/nav/index";
import "./index.scss";
import Footer from "../../components/footer";
import Blurb from "../../components/blurb";
import Stats from "../../components/stats";
import classnames from "classnames";

import renewableEnergy from "./img/renewable-energy.svg";
import landAdmin from "./img/land-administration.svg";
import ownerRelations from "./img/owner-relations.svg";
import landExplore from "./img/land-exploration.svg";
import assetManagement from "./img/asset-management.svg";
import softwareEvaluation from "./img/software-evaluation.svg";

import softwareSystems from "./img/software-systems.svg";
import softwareIntegration from "./img/software-integration.svg";
import dataReporting from "./img/data-reporting.svg";
import bestPractices from "./img/best-practices.svg";

import calloutImage from "../home/img/book-consult-callout.svg";
import CalloutBox from "../../components/callout-box";

import analytics from "./img/analytics.svg";
import accounting from "./img/accounting.svg";
import supplyChain from "./img/supply-chain.svg";

const consultingServices = [
  {
    icon: renewableEnergy,
    title: "Renewable Energy",
    copy: "Our team offers unparalleled data organization, software implementation, and reporting for renewable energy land and supply chain contracts.  We define and extract pertinent terms that lay the foundation for the management of our clients’ data and accurate financial projections which equals success for your wind and solar projects.  We also implement best practices for use of technology and land data maintenance in today’s era of modern land management.",
  },
  {
    icon: landAdmin,
    title: "Land Administration",
    copy: "With experts in division order, lease records, land, and data analysts, we can provide our clients with the support they need to focus on the operation of their business.  We offer a variety of services ranging from full or part-time day-to-day Land Administration support to data clean up projects concentrated on data integrity that results in accurate and reliable reporting.  Energy Point's experience in all of the top energy software platforms allows us to quickly be impactful and effective. ",
  },
  {
    icon: ownerRelations,
    title: "Owner Relations",
    copy: "At Energy Point, we understand that effective problem solving is key to delivering results that exceed our clients' expectations. Our service-oriented culture and expertise in the energy field enable us to develop customized solutions and implement best practices to meet our clients' unique needs. Our team is committed to being responsive and utilizing technology to effectively communicate with both our clients and their stakeholders throughout the problem-solving process.",
  },
  {
    icon: landExplore,
    title: "Land Exploration & Production",
    copy: "Our team of dynamic former in-house landmen prioritize a ‘client first’ mentality. From title verifications, due diligence projects, regulatory affairs, Lease & ROW negotiations, GIS Mapping, well proposals and tracking and drill schedule management. Our experienced team of landmen will work to meet all of your land needs, the right tool for the right job.",
  },
  {
    icon: assetManagement,
    title: "Asset & Energy Estate Management",
    copy: "We offer proactive management of private mineral owner assets. Digitally organize and capture land, production and accounting data, create reporting mechanisms in order to give peace of mind to our mineral owners that their assets are being actively managed, reconciled and monetized. We leverage our team and network to work with operators to transfer interests timely, ensure lease terms are met, and any suspended funds are released.",
  },
  {
    icon: softwareEvaluation,
    title: "Software Evaluation & Implementation",
    copy: "Energy Point starts with defining our clients' business needs and recommending the best-in-class software solutions.  This saves our clients time and money with our expertise with top energy software vendors and our unique ability to tailor technology and process solutions. ",
  },
  {
    icon: analytics,
    title: "Analytics",
    copy: "Empower your business with cutting edge analytics solutions. Energy Point leverages advanced data analysis to uncover valuable insights, enhance decision-making, and optimize performance. We can help your business make informed decisions by analyzing data to gain insights on performance, trends and behaviors. Partnering with Energy Point, we can transform your raw data into actionable intelligence with our tailored analytics expertise and experience.",
  },
  {
    icon: accounting,
    title: "Accounting",
    copy: "Energy Point assists in various Accounting functions using GAAP and COPAS Accounting Principles. Our team is experienced in software implementation, process improvements and day to day operations. Scope of accounting work may include Accounts Payable (AP), Accounts Receivable (AR), Joint Interest Billing, Revenue (JIB), Financial Reporting (FAR), Audit preparation and compliance, Bank Reconciliations, maintenance of General Ledger and Chart of Accounts, Accruals and Amortizations, and others based on clients’ needs.",
  },
  {
    icon: supplyChain,
    title: "Supply Chain",
    copy: "Elevate your business’s efficiency and performance with Energy Point’s comprehensive supply chain consulting. We specialize in optimizing end-to-end processes, software implementations, process improvements and streamlining logistics. Partner with us to navigate complexities and transform your supply chain into a competitive edge.",
  },
];

const experienceServices = [
  {
    icon: softwareSystems,
    title: "Software Systems",
    copy: "The team has 'hands on' knowledge of all of the energy software options on today's market such as Quorum, W Energy, P2 and Enertia. We have worked with each of these platforms from design, to implementation and maintenance.",
  },
  {
    icon: softwareIntegration,
    title: "Software Implementation & Integration",
    copy: "Our deep industry experience enables our team to provide insight and guidance to stay on track with software and reporting implementations as well as identify and evaluate associated data risks. This allows for best in class solutions and innovation.",
  },
  {
    icon: dataReporting,
    title: "Data Management & Reporting",
    copy: "Our data savvy team provides our clients with the right tools to capture Key Performance Indicators (KPIs), quality controls, and the ability to connect real time data. This allows our clients to remain nimble, accurate, and agile in their decision making process.",
  },
  {
    icon: bestPractices,
    title: "Best Practices",
    copy: "We do not look for the bias or the common practice but rather for the most efficient, effective and affordable solution for all parties. Our team is compiled of industry leaders and experts who have developed and implemented best practice solutions across various organization sizes from small private equity to large public corporations.",
  },
];

const TeamPage = () => {
  const [tab, setTab] = useState("consulting");

  const consultingServicesComponent = (
    <>
      {consultingServices.map((service) => {
        return (
          <div
            className={classnames("service", {
              active: tab === "consulting",
            })}
            key={service.title}
          >
            <div className="icon-wrapper">
              <img src={service.icon} alt={service.title} />
            </div>
            <h3>{service.title}</h3>
            <p>{service.copy}</p>
          </div>
        );
      })}
    </>
  );

  const experienceServicesComponent = (
    <>
      {experienceServices.map((service) => {
        return (
          <div
            className={classnames("service", {
              active: tab === "experience",
            })}
            key={service.title}
          >
            <div className="icon-wrapper">
              <img src={service.icon} alt={service.title} />
            </div>
            <h3>{service.title}</h3>
            <p>{service.copy}</p>
          </div>
        );
      })}
    </>
  );

  return (
    <div className="services-page">
      <NavBar internal />
      <div className="content-wrapper">
        <Blurb
          heading="Ready to use Energy Point?"
          subtext="We are a consulting company that partners with our clients to define problems and provide solutions for energy land data management technology and process. "
        />
        <div className="tabs">
          <ul className="tab-switch">
            <li
              className={classnames({
                active: tab === "consulting",
              })}
              onClick={() => setTab("consulting")}
            >
              Consulting
            </li>
            <li
              className={classnames({
                active: tab === "experience",
              })}
              onClick={() => setTab("experience")}
            >
              Experience
            </li>
          </ul>
          <div className="tab-container">
            {tab === "consulting" && consultingServicesComponent}
            {tab === "experience" && experienceServicesComponent}
          </div>
        </div>
        <Stats />
        <CalloutBox
          header="Book a consult with us today"
          copy="We leverage our extensive experience and expertise in each field in order to positively impact the bottom line through efficient uses of technology, reporting, and process."
          ctaCopy="Click to Book"
          image={calloutImage}
        />
      </div>
      <Footer />
    </div>
  );
};
export default TeamPage;
