import React, { useState, useEffect, useRef } from "react";
import Button from "../button/index";
import "./index.scss";
import heroBG from "./img/hero-bg.jpg";
import heroBG1 from "./img/hero-bg1.jpg";
import heroBG2 from "./img/hero-bg2.jpg";
import heroBG3 from "./img/hero-bg3.jpg";
import heroBG4 from "./img/hero-bg4.jpg";
import heroBG5 from "./img/hero-bg5.jpg";
import heroBG6 from "./img/hero-bg6.jpg";

const Hero = () => {
  const [bgIndex, setBgIndex] = useState(0);
  const heroBgRef = useRef<HTMLDivElement | null>(null);

  const bgImages = [
    `url(${heroBG})`,
    `url(${heroBG1})`,
    `url(${heroBG2})`,
    `url(${heroBG3})`,
    `url(${heroBG4})`,
    `url(${heroBG5})`,
    `url(${heroBG6})`,
  ];

  const cycleBackground = () => {
    setBgIndex((prevIndex) => (prevIndex + 1) % bgImages.length);

    if (heroBgRef.current) {
      // Remove the active class from the hero-bg element
      heroBgRef.current.classList.add("active");

      // Adding a delay to ensure the class is removed before re-adding it
      setTimeout(() => {
        if (heroBgRef.current) {
          // Apply fade-in effect by toggling the active class
          heroBgRef.current.classList.add("active");

          // Remove the active class after a short delay for more visibility
          setTimeout(() => {
            if (heroBgRef.current) {
              heroBgRef.current.classList.remove("active");
            }
          }, 500);
        }
      }, 10);
    }
  };

  useEffect(() => {
    // Cycle background when the component mounts
    cycleBackground();

    // Set interval to cycle the background every 5 seconds
    const intervalId = setInterval(cycleBackground, 5000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="hero-wrapper">
      <div className="scrim"></div>
      <div
        className="hero-bg"
        ref={heroBgRef}
        style={{ backgroundImage: bgImages[bgIndex] }}
      ></div>
      <div className="content-container">
        <h1>Data solutions for your business</h1>
        <p>
          We leverage our extensive experience and expertise in each field in
          order to positively impact the bottom line through efficient uses of
          technology, reporting, and process.
        </p>
        <Button
          href="https://calendly.com/businessdevelopment-epc"
          target="_blank"
          xlarge
        >
          Book a consult
        </Button>
      </div>
    </div>
  );
};

export default Hero;
